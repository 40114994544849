.header {
  display: grid;
  grid-template-columns: var(--gutter) calc(var(--container-width) * 0.85) calc(var(--container-width) * 0.15) var(--gutter);
  grid-template-areas: ". wordmark menu ."
  "nav nav nav nav";

  &__menu-button{
    grid-area: menu;
  }

  &__menu {
    grid-area: nav;
  }

  .home-logo {
    align-items: center;
    display: grid;
    grid-area: wordmark;
  }
}

.footer {
  display: grid;
  grid-template-columns: var(--gutter) var(--container-width) var(--gutter);
  grid-template-areas: '. nav .'
  '. copyright .';

  &__copyright {
    grid-area: copyright;
  }

  &__menu {
    grid-area: nav;
  }
}

.main {
  display: grid;
  grid-template-columns: var(--gutter) var(--container-width) var(--gutter);
  grid-template-areas: ". content ."
  ". sidebar ."
}

.content {
  grid-area: content;
}

.sidebar {
  grid-area: sidebar;
}