body,
.content {
  font-family: var(--bodyFont);
  color: var(--darkgrey);

  a {
    @include link-text;
    color: var(--accent-2);
    &:visited {
      color: var(--accent-2);
    }
    &:focus {
      outline: 3px solid var(--accent-2);
    }
    &[target='_blank']::after {
      content:'';
      display: inline-block;
      background-image: url("/assets/icons/external_link.png");
      inline-size: 0.75em;
      block-size: 0.75em;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      margin-inline: 0.5ch;
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--headingFont);
  color: var(--black);
  margin: 0;
}