.header__wordmark {
  inline-size: 100%;
  max-inline-size: 15rem;
  block-size: auto;
  padding-block: 1rem;
}

.section__hero {
  margin-block: 1rem 1.5rem;
}

.hero__sub-heading {
  margin-block:  0.5rem;
}