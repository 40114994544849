pre[class*='language-'] {
  @include code-block;
  padding: 1em;
}

p > code {
  background: rgb(243, 243, 243);
  border-radius: 0.2em;
  padding: 0.1em 1ch;
  font-style: italic;
}

.posts .content {

  line-height: 1.9;

  a {
    text-decoration: underline;
  }

  h2,h3,h4,h5,h6 {
    margin-block-start: 0.75em;
    line-height: 1;

    & + p:not(p.tldr__desc) {
      margin-block-start: 0.5rem;
    }
  }

  p:not(p.tldr__desc) {
    margin-inline-start: 1rem;
  }
}

/* LATEST POSTS */
.latest-posts {
  display: grid;
  grid-template-areas: 'latest-title'
  'posts'
  'see-all';
  align-items: baseline;
  
  &__heading {
    grid-area: latest-title;
    margin-block-end: 1rem;
  }

  .older-posts {
    grid-area: see-all;
    text-align: end;
  }

  &__list {
    display: grid;
    grid-area: posts;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    
    &:not(:last-of-type)::after {
      content: '';
      display: block;
      block-size: 1px;
      background-color: var(--accent-2);
      margin-block: 1rem;
      opacity: 0.5;
    }

    &:last-of-type {
      margin-block-end: 2rem;
    }
  }

  @media (min-width: $md) {
    grid-template-areas: 'latest-title . see-all'
    'posts posts posts';

    &__list {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 3rem;
    }

    &__item {
      padding: 1rem;

      &:not(:last-of-type)::after {
        display: none;
      }
    }
  }

}

/* ARCHIVE POSTS */
.post-list {
  margin-block-end: 1em;
}

/* TLDR */
.tldr {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas: 'tldr-heading'
  'tldr-desc';
  align-items: center;
  margin-block-end: 3rem;
  outline: 4px solid var(--accent-2);
  outline-offset: -4px;
  position: relative;

  &__background {
    background-color: var(--accent-2);
    display: block;
    grid-column: 1 / -1;
    grid-row: 1/-1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
  
  h2.tldr__heading {
    color: var(--white);
    grid-area: tldr-heading;
    padding: 0.4rem 0.6rem;
    margin: 0;
  }
  
  &__desc {
    grid-area: tldr-desc;
    margin: 0.2rem;
    padding: 0.625rem;
    background-color: var(--white);
  }
}

@media screen and (min-width: $sm) {
  .tldr {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-columns: auto;
    grid-template-areas: 'tldr-heading tldr-desc';
  }
}