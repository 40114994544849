@mixin link-text {
  text-decoration: none;
  color: var(--accent-1);

  &:visited {
    color: var(--accent-1);
  }

  &:hover, &:focus {
    text-decoration: underline;
  }

  &[aria-current] {
    text-decoration: none;
    cursor: default;
    font-weight: bold;
  }
}

@mixin code-block {
  font-family: monaco, ui-monospace, monospace;
  line-height: 1.25;
}

@mixin visually-hidden  {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  block-size: 1px;
  inline-size: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}