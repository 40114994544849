// Breakpoints
$xs: 25rem;
$sm: 37.5rem;
$md: 52.5rem;
$lg: 67.5rem;

:root {
  --bodyFont: helvetica, tahoma, ui-sans-serif, sans-serif;
  --headingFont: 'Arial Black', var(--bodyFont);
  --black: #212529;
  --darkgrey: #303030;
  --white: #F5F7FA;
  --background: var(--white);
  --accent-1: #ae2142;
  --accent-2: #40787D; // #56A3A6
  --accent-3: #ffbc42;
  --shadowColor: var(--balck);
  --box-shadow: 0.25rem 0.3rem 0.5rem var(--shadowColor);
  --border-radius: 0.5rem;
  --gutter: minmax(2vw, auto);
  --container-width: 90vw;
  --outline: 1px solid red; 
}

// https://coolors.co/f5f7fa-212529-ae2142-ffbc42-56a3a6


@media (min-width: $sm) {
  :root {
    --container-width: 80vw;
  }
}

@media (min-width: $lg) {
  :root {
    --container-width: 52.5rem;
  }
}