.header {

  &__menu--main {
    padding-block: 1em;
    line-height: 1.5;
    font-size: 1.15em;
  }

  &__menu {
    background: var(--background);
    inline-size: 100%;
    position:absolute;
    inset-inline-start: 0;
    margin-block-start: 3.75rem;
    margin-inline-start: initial;
    transition: 0.3s;
    z-index: 20;

    .header__menu-button[aria-expanded='false'] + & {
      margin-inline-start: -100%;
      visibility: hidden;
    }

    &-list {
      list-style: none;
      padding: 0;
      margin:0;
      text-align: center;
    }
  
    &-item {
      padding: 0 1rem;
    }

    &-link {
      @include link-text;

      &:focus-visible {
        font-weight: 700;
      }
    }

    &-button {
      cursor: pointer;
      background-color: transparent;
      border: 0;
      padding: 1rem 0;
      text-align: right;

      span {
        margin-inline-end: 1ch;
      }
    }

    &-button[aria-expanded='true'] {
      z-index: 20;
      .octicon-three-bars {
        display:none;
      }
    }

    &-button[aria-expanded='false'] {
      .octicon-x {
        display:none;
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .header__menu-button span {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    block-size: 1px;
    inline-size: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  }
}

.footer {

  &__menu-list {
    display: flex;
    list-style: none;
    padding: 0;
    flex-wrap: wrap;
  }

  &__menu-item {
    padding-inline: 1em;

    &:first-of-type {
      padding-inline-start: 0;
    }

    &:last-of-type {
      padding-inline-end: 0;
    }
  }

  &__menu-link {
    @include link-text;
  }
}