body {
  margin: 0;
  background-color: var(--background);

  &.show-overlay {
    position: fixed;
    inline-size: 100%;
    overflow-y: hidden;
    

    & > *:not(.header) {
      &::before {
        content: '';
        display: block;
        block-size: 100%;
        inline-size: 100%;
        position: absolute;
        z-index: 5;
        background: rgba(75,75,75,0.8);
      }
    }
  }
}


.sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  block-size: 1px;
  inline-size: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;

  &:focus, &:focus-within {
    clip: initial;
    clip-path: initial;
    block-size: initial;
    inline-size: initial;
    margin: initial;
    overflow: initial;
    padding: initial;
    position: initial;
  }
}

.skip-to-main {
  @extend .sr-only;
  background: var(--accent-2);
  color: var(--white);
  box-shadow: var(--box-shadow);
  font-weight: 700;
  z-index: 20;
  display: block;

  &:focus, &:focus-within {
    inline-size: 100%;
    padding-block: 1rem;
    text-align: center;
    position: absolute;
  }
}