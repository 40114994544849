.resume {

  &-list__item:not(:last-of-type) {
    margin-block-end: 1rem;
  }

  &-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &-detail {
    list-style: none;
  
    &__title {
      display: flex;
      cursor: pointer;
      grid-template-columns: 1fr;
      align-items: baseline;
      justify-content: space-between;
      position: relative;

      h3 {
        display: inline-block;
      }

      &::after {
        $chevronSize: 0.5rem;  
        content: '';
        display: inline-block;
        border-bottom: $chevronSize solid var(--darkgrey);
        border-left: calc($chevronSize / 2) solid transparent;
        border-right: calc($chevronSize / 2) solid transparent;
        transform: rotate(-180deg);
        transition: transform 0.3s ease-in-out;
      }
    }

    &[open] .resume-detail__title::after {
      transform: rotate(0deg);
    }
    
    &__title::marker,
    &__title::-webkit-details-marker { 
      content: '';
      display: none;
    }
  }

  &-detail li {
    list-style: initial;
  }
  
  &-section {
    margin-block-end: 2rem;
  }

  &-section-heading {
    color: var(--accent-2);
    
    &::after {
      content: '';
      display: block;
      block-size: 0.0625rem;
      background-color: var(--darkgrey);
      margin-block-end: 1rem;
    }
  }

  &-detail__date {
    color: var(--accent-1);
    font-weight: 600;
  }

  &-detail__organization {
    font-style: italic;
    &::before {
      content: '|';
      display: inline-block;
      font-style: initial;
      margin-inline: 1ch;
    }
  }

  &-section__skills {
    .skillsets {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 2rem;
    }

    .skillset__list {
      padding: 0;
      margin: 0;
      list-style-position: inside;
    }

    @media (min-width: 31.25rem) {
      .skillset__list {
        columns: 2;
      }
    }
  }
}
